import { validators } from "vue-form-generator";
import i18n from "@/plugins/i18n";

export const schema = {
  fields: [
    {
      type: "input",
      inputType: "number",
      label: "value",
      model: "value",
      required: true,
      placeholder: "value",
      min: 0.01,
      validator: [
        validators.required.locale({
          fieldIsRequired: i18n.t("valueRequired"),
        }),
        validators.number.locale({
          fieldIsRequired: i18n.t("valueRequired"),
          invalidNumber: i18n.t("invalidNumber"),
          numberTooSmall: i18n.t("numberMustBeGreatedZero"),
        }),
      ]
    },
    {
      type: "accordion",
      model: "products",
      required: true,
      items: [],
      checked: true,
      min: 1,
      validator: [
        validators.required.locale({
          fieldIsRequired: i18n.t("productsRequired"),
        }),
        validators.array.locale({
          fieldIsRequired: i18n.t("selectAtLeastOneItem"),
        })
      ],
    },
  ]
};
