<template>
  <div>
    <crud-form
      v-if="loaded"
      :schema="schema"
      :path="'tag-item-price/recalculate'"
      :form-title="$t('tagItemPriceRecalculation')"
      :redirect-route="'TagItemRecalculationList'"
      :model="{
        value: 0,
        products: [],
        tagItem
      }"
    />
  </div>
</template>

<script>
  import CrudForm from '../../components/CrudForm';
  import { schema } from './schema/tagItemRecalculation'
  import {APIService} from "../../services/api";

  export default {
    name: 'AddTagItemRecalculation',
    components: {
      CrudForm
    },
    props: ['tagItemId'],
    data: () => {
      return {
        loaded: false,
        schema,
        tagItem: null,
      }
    },
    async beforeMount() {
      this.tagItem = await APIService.get(`tag-item/${this.tagItemId}?join=relatedTagItems&join=relatedTagItems.relatedTagItem`);
      const relatedTagItemsId = this.tagItem.relatedTagItems.map(tagItemRelation =>
        tagItemRelation.relatedTagItem ? tagItemRelation.relatedTagItem.id : null
      ).filter(tagItemId => tagItemId !== null);
      if (relatedTagItemsId.length > 0) {
        const items = await APIService.get(`tag-item?join=tag&join=products&join=products.tagItems&filter=id||$in||${relatedTagItemsId.join(',')}&filter=tag.token||$eq||category&filter=products.tagItems.id||$eq||${this.tagItemId}`);
        const key = this.schema.fields.findIndex(field => field.type === 'accordion');
        if (key) {
          this.schema.fields[key].items = items.map(tagItem => {
            return {
              header: tagItem.value,
              value: tagItem.id,
              items: tagItem.products.map(product => {
                return {
                  id: product.id,
                  name: product.name,
                };
              }),
            }
          });
        }
      }
      this.loaded = true;
    }
  }
</script>
